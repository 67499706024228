@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* Body */

  body {
    @apply bg-white text-black
    transition-[background-color] duration-150 ease-in-out;
  }



  /* Typography */
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-base;
  }
  h6 {
    @apply text-sm;
  }
  p {
    @apply text-lg;
  }

  hr {
    @apply w-full h-px bg-gray-300 border-0;
  }

  .tag {
    @apply px-2 py-1 border rounded-lg;
  }
  .tag-main {
    @apply bg-opacity-10 bg-main-400 text-main-500 border-main-500;
  }
  .tag-success {
    @apply bg-opacity-10 bg-green-500 text-green-600 border-green-600;
  }
  .tag-warning {
    @apply bg-opacity-10 bg-yellow-500 text-yellow-600 border-yellow-600;
  }
  .tag-danger {
    @apply bg-opacity-10 bg-red-500 text-red-600 border-red-600;
  }



  /* Nav */

  nav {
    @apply fixed top-0 left-0 right-0 border-b z-10
    bg-gray-100 border-b-gray-300
  }
  .nav-container {
    @apply relative max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl px-4 h-16 mx-auto grid grid-cols-[auto,1fr] place-items-center;
  }

  nav .nav-container .logo {
    @apply focus:outline-none
    opacity-100 focus:opacity-70
    md:opacity-70 md:hover:opacity-100 md:focus:opacity-100
    transition-opacity duration-150 ease-in-out;
  }

  .nav-container .menubtn-container {
    @apply w-full h-full flex items-center justify-end
    md:hidden;
  }
  .nav-container .menubtn {
    @apply w-12 h-12 flex items-center justify-center text-2xl rounded-lg focus:outline-none focus:ring-4
    text-gray-400 focus:ring-gray-200
    transition-[box-shadow] duration-150 ease-in-out;
  }

  .nav-container .nav-items {
    @apply flex z-20
    fixed flex-col top-0 right-0 bottom-0 w-full sm:w-[90vw] sm:border-l translate-x-[100%]
    bg-white border-gray-300
    md:relative md:flex-row md:w-full md:h-full md:justify-end md:border-none md:translate-x-0
    md:bg-transparent
    transition-transform duration-300 ease-in-out;
  }
  .nav-container .nav-items.open {
    @apply translate-x-0;
  }
  
  .nav-container .nav-items .mobile-header {
    @apply flex items-center justify-between w-full h-16 border-b px-4 mb-4
    bg-gray-100 border-gray-300
    md:hidden;
  }

  .nav-container .nav-items .nav-item {
    @apply relative px-4 focus:outline-none
    md:leading-[4rem] md:align-middle md:h-full
    md:hover:text-main-500 focus:text-main-500
    transition-colors duration-150 ease-in-out;
  }
  .nav-container .nav-items .nav-item.active {
    @apply 
    text-main-500
    md:after:content-[''] md:after:absolute md:after:bottom-[-1px] md:after:right-0 md:after:left-0 md:after:h-[2px]
    md:text-black md:after:bg-main-500
    md:hover:text-main-500 focus:text-main-500;
  }

  .nav-container .nav-items .nav-dropdown {
    @apply relative px-4 cursor-pointer text-left
    focus:outline-none
    md:h-full;
  }
  .nav-container .nav-items .nav-dropdown.active {
    @apply 
    text-main-500
    md:after:content-[''] md:after:absolute md:after:bottom-[-1px] md:after:right-0 md:after:left-0 md:after:h-[2px]
    md:text-black md:after:bg-main-500
    md:hover:text-main-500 focus:text-main-500;
  }
  .nav-container .nav-items .nav-dropdown legend {
    @apply md:leading-[4rem] md:align-middle
    transition-[color] duration-150 ease-in-out;
  }
  .nav-container .nav-items .nav-dropdown:hover legend,
  .nav-container .nav-items .nav-dropdown:focus legend,
  .nav-container .nav-items .nav-dropdown[data-open] legend {
    @apply text-main-500;
  }
  .nav-container .nav-items .nav-dropdown .nav-dropdown-items {
    @apply hidden right-0 border-t shadow-lg py-1 z-20 origin-top rounded-b-lg w-fit
    md:absolute md:block scale-y-0
    bg-gray-100 border-gray-300
    transition-[transform] duration-150 ease-in-out;
  }
  .nav-container .nav-items .nav-dropdown[data-open] .nav-dropdown-items {
    @apply  scale-y-100 block;
  }
  .nav-container .nav-items .nav-dropdown .nav-dropdown-items .nav-dropdown-item {
    @apply whitespace-nowrap px-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 text-black
    transition-[background-color,color] duration-150 ease-in-out;
  }
  .nav-container .nav-items .nav-dropdown .nav-dropdown-items .nav-dropdown-item.active {
    @apply text-main-500;
  }

  nav .nav-container .sidebar-bg {
    @apply fixed top-0 left-0 right-0 bottom-0 hidden opacity-80 z-10
    md:hidden
    bg-white;
  }

  nav .nav-container .sidebar-bg.open {
    @apply block md:hidden;
  }

  body:has(.sidebar-bg.open) {
    @apply overflow-hidden md:overflow-auto;
  }

  .image-focus {
    @apply z-20 fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center cursor-zoom-out;
  }
  .image-focus img {
    @apply w-auto max-w-[90vw] h-auto max-h-[90vh];
  }

  body:has(.image-focus) {
    @apply overflow-hidden md:overflow-auto;
  }



  /* Header */
  
  .header {
    @apply w-full p-4 md:py-8 bg-[url(./assets/header.png)] bg-cover bg-center flex flex-col items-center;
  }



  /* Footer */
  footer {
    @apply absolute w-full border-none border-t flex items-center
    translate-y-[-6rem] h-24
    md:translate-y-[-5rem] md:h-20
    bg-main-500 border-gray-300 text-white
    transition-[background-color,border-color] duration-150 ease-in-out;
  }
  footer .footer-container {
    @apply w-full max-w-screen-lg mx-auto px-4
    flex flex-col items-center justify-center
    md:grid md:grid-cols-2
    lg:grid-cols-[3fr,2fr];
  }
  footer p {
    @apply text-center md:text-left text-sm
    transition-[color] duration-150 ease-in-out;
  }
  footer .footer-link {
    @apply font-bold hover:underline focus:outline-none focus:underline;
  }
  footer .footer-btns {
    @apply flex gap-4 items-center
    md:flex-col md:gap-0 md:items-end
    lg:grid lg:grid-cols-2 lg:justify-end;
  }
  footer .footer-btns .footer-btn {
    @apply focus:outline-none text-xl
    md:flex md:items-center md:gap-2
    text-white hover:text-gray-300 focus:text-gray-300
    transition-[color] duration-150 ease-in-out;
  }
  footer .footer-btns .footer-btn:nth-child(odd) {
    @apply lg:flex-row-reverse lg:justify-end;
  }
  footer .footer-btns .footer-btn:nth-child(even) {
    @apply lg:justify-end;
  }



  /* Containers */

  .container {
    @apply max-w-screen-xl mx-auto p-4 min-h-[100vh]; 
  }
  nav + .container {
    @apply pt-20;
  }
  body:has(footer) .container {
    @apply pb-28 md:pb-24;
  }

  .card {
    @apply rounded-lg border bg-gray-50 overflow-hidden
    border-gray-300;
  }
  .card .card-header {
    @apply p-4
    bg-gray-100;
  }
  .card .card-header + .card-body {
    @apply border-t
    border-gray-300;
  }
  .card .card-body {
    @apply p-4;
  }
  .card .card-body + .card-footer {
    @apply border-t
    border-gray-300;
  }
  .card .card-footer {
    @apply p-4
    bg-gray-100;
  }

  .hide-scrollbar::-webkit-scrollbar {
    @apply hidden;
  }

  details summary {
    @apply cursor-pointer;
  }



  /* Buttons */

  .btn {
    @apply uppercase px-4 py-2 flex items-center justify-center gap-2 rounded-lg font-bold focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed
    transition-opacity duration-150 ease-in-out;
  }

  .btn-main {
    @apply focus:outline-none focus:ring-2
    text-white bg-main-500 hover:bg-main-400 disabled:bg-main-500 focus:ring-main-200
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }
  .btn-outline-main {
    @apply bg-transparent border focus:outline-none focus:ring-2
    text-main-500 border-main-500 hover:bg-main-500 hover:text-white focus:ring-main-200
    disabled:bg-transparent disabled:text-main-500
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }

  .btn-danger {
    @apply focus:outline-none focus:ring-2
    text-white bg-red-600 hover:bg-red-500 disabled:bg-red-600 focus:ring-red-300
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }
  .btn-outline-danger {
    @apply bg-transparent border focus:outline-none focus:ring-2
    text-red-600 border-red-600 hover:bg-red-600 hover:text-white focus:ring-red-300
    disabled:bg-transparent disabled:text-red-600
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }


  .link {
    @apply cursor-pointer text-main-500 hover:underline hover:text-main-600 focus:underline focus:text-main-600 focus:outline-none
    disabled:opacity-50 disabled:focus:text-main-500 disabled:cursor-not-allowed
    transition-[color] duration-150 ease-in-out;
  }



  /* Forms */

  .form-group {
    @apply flex flex-col gap-2;
  }

  .form-group.error label {
    @apply text-red-600
  }
  .form-group.error .form-control {
    @apply border-red-600 focus:ring-red-500;
  }

  .form-group label {
    @apply relative
    transition-[color] duration-150 ease-in-out;;
  }
  .form-group.required label {
    @apply after:relative after:content-["_*"]
    after:text-red-600
    after:transition-[color] duration-150 ease-in-out;
  }
  .form-group.recommended label {
    @apply after:relative after:content-["_(Recommended)"]
    after:text-gray-400
    after:transition-[color] duration-150 ease-in-out;
  }

  .form-control {
    @apply px-2 rounded-lg border focus:outline-none focus:ring-2
    text-black bg-white border-gray-300 focus:ring-gray-200
    transition-[background-color,color,border-color,box-shadow,opacity] duration-150 ease-in-out;
  }

  .form-control:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .input-group {
    @apply grid grid-cols-[1fr,2fr];
  }
  .input-group-reverse {
    @apply grid-cols-[2fr,1fr]
  }
  .input-group-3 {
    @apply grid-cols-[1fr,2fr,1fr];
  }
  .input-group .form-control {
    @apply border-l-0 rounded-none w-full;
  }
  .input-group .form-control:first-child {
    @apply border-l rounded-l-lg
    bg-gray-100;
  }
  .input-group .form-control:last-child {
    @apply rounded-r-lg;
  }
  .input-group .input-group-reverse .form-control:first-child {
    @apply bg-white;
  }
  .input-group .input-group-reverse .form-control:last-child,
  .input-group .input-group-3 .form-control:last-child {
    @apply bg-gray-100;
  }

  .form-checkboxes {
    @apply flex flex-col items-center gap-2;
  }
  .form-checkbox {
    @apply flex items-center gap-2;
  }
  .form-checkbox .input-checkbox {
    @apply w-4 h-4 focus:outline-none focus:ring-2
    text-main-500 bg-gray-100 border-gray-300 focus:ring-main-300
    transition-[box-shadow] duration-150 ease-in-out;
  }
  .form-checkbox label {
    @apply text-sm text-black
    transition-[color] duration-150 ease-in-out;
  }
  .form-checkbox.error label {
    @apply text-red-600;
  }



  /* Avatar */

  .avatar {
    @apply rounded-full border-4 border-gray-300 bg-gray-100 object-cover object-center;
  }



  /* Spinner */
  
  .spinner {
    @apply flex items-center justify-center relative w-[var(--size)] h-[var(--size)]
    after:absolute after:content-[''] after:top-0 after:bottom-0 after:right-0 after:left-0
    after:border-[calc(var(--size)*0.1)] after:border-r-transparent after:border-b-transparent after:border-l-transparent after:rounded-full
    after:border-main-500
    after:animate-spin;
  }
  .spinner img {
    @apply w-[calc(var(--size)*0.65)] h-[calc(var(--size)*0.65)];
  }



  /* Admin Table */
  .admin-table {
    @apply pt-px;
  }
  .admin-table th {
    @apply text-left;
  }
  .admin-table th,
  .admin-table td {
    @apply overflow-hidden max-w-32 md:max-w-48 truncate pr-2;
  }
  .admin-table th:last-child,
  .admin-table td:last-child {
    @apply pr-0;
  }
}